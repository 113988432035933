import React, { useRef } from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Span, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import Images from "components/images";

import { useHistory } from 'react-router-dom';
import PinterestLayout from "components/pinterestLoayout";
import ImageSlider from "components/imageSlisder";
import couches from "assets/portraitsv2/couches";
import landscapepics from "assets/portraitsv2/landscapepics";




const animateMyKids = (play) => {
	// collect all the divs
	var divs = document.getElementsByClassName(play + 'kid');
	// get window width and height



	setInterval(() => {
		// i stands for "index". you could also call this banana or haircut. it's a variable

		if (!document.getElementById('play')) {
			return
		}
		const i = getRandomNumber(0, divs.length)


		// shortcut! the current div in the list
		var thisDiv = divs[i];

		// get random numbers for each element
		const randomTop = getRandomNumber(0, document.getElementById('play').offsetHeight);
		const randomLeft = getRandomNumber(0, document.getElementById('play').offsetWidth);

		// update top and left position
		thisDiv.style.top = randomTop + "px";
		thisDiv.style.left = randomLeft + "px";


	}, 1000)

	// function that returns a random number between a min and max
	function getRandomNumber(min, max) {

		return parseInt(Math.random() * (max - min) + min);

	}
}
const Component = () => {
	const history = useHistory()
	const inputRef = useRef(null)
	const images = couches; // Replace with your image URLs
	const imagesForLandscape = landscapepics; // Replace with your image URLs
	const userAgent = navigator.userAgent.toLowerCase();
	const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);

	React.useEffect(() => {
		setTimeout(() => {
			animateMyKids('play')
		}, 1000)



	}, [])

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Beans Art
			</title>
			{/* <meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} /> */}
		</Helmet>


		<div style={{
			position: 'fixed',
			top: 0,
			zIndex: 0,
			width: '100vw',
			height: '100vh',
			opacity: 0.2
		}} id="play"  >


			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>

			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
			<Images ></Images>
		</div>

		<div style={{
			zIndex: 1,
			position: 'relative'
		}} >
			<Section style={{
				background: 'white'
			}} border-width={0}>


				<Override slot="SectionContent" border-color="#f4eaea" />
				<Box
					display="flex"
					padding="12px 0"
					justify-content="space-between"
					align-items="center"
					flex-direction="row"
					md-flex-direction="column"
				>
					<Text
						color="--dark"
						margin="0"
						md-margin="0px 0 20px 0"
						font="--lead"
					>
						BeansIo
					</Text>

				</Box>
			</Section>
			<Section
				style={{
					background: 'white',
					height: '100vh'
				}}
				padding="112px 0 0px 0"
				// background="--color-primary"
				md-padding="96px 0 0px 0"
				sm-padding="72px 0 0px 0"
				font="6px sans-serif"
				border-color="#f9e8e8"
			>
				<div style={{
					display: 'grid',
					gridTemplateColumns: ' 1fr 1fr',
					textAlign:isMobile?'center':'left'

				}} >
					<div><Override
						slot="SectionContent"
						flex-direction="row"
						flex-wrap="wrap"
						display="inline-block"
						text-align="center"
						flex="0 1 auto"
						order="-1"
					/>
						<Box
							display="inline-block"
							width="90%"
							flex-direction="column"
							justify-content="center"
							align-items="center"
							lg-width="100%"
							lg-align-items="center"
							lg-margin="0px 0px 60px 0px"
							sm-margin="0px 0px 40px 0px"
							sm-padding="0px 0px 0px 0px"
							padding="0px 0px 0px 0"
						>
							<Text
								margin="auto"
								color="--dark"
								font="normal 900 62px/1.5 --fontFamily-sans"
								
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								padding="20px 0px 0px 0px"
							>
								{" "}we turn {" "}

								<Span
									color="--secondary"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									AI
								</Span>
								{" "}generated{" "}
								<Span
									color="--qtheme-color-secondary"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									ART
								</Span>
								{" "}you{" "}
								<Span
									color="--qtheme-color-secondary"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									{" \n\t\t\t\t"}IMAGINE
								</Span>
								{" "}into a {" "}
								<Span
									color="--qtheme-color-secondary"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									FRAMED MASTERPIECE
								</Span>
							</Text>

							<Text
								margin="40px 0px 40px 0px"
								font="normal 300 16px/1.5 --fontFamily-mono"
								lg-text-align="center"
								padding="0px 0px 0px 0px"
								text-align="center"
								color="--dark"
							>
								Framed AI art generated by you and delivered to your door steps.
							</Text>

							<div style={{
								borderRadius: '5px',
							}} >
								<Input ref={inputRef} style={{
									width: '700px',
									maxWidth: '90vw',
									height: '60px'
								}} placeholder='Art prompt......Try typing dogs that fly...'  ></Input>
								<Button onClick={() => {
									if (inputRef.current.value && inputRef.current.value.length > 10) {
										history.push(`/app?prompt=${inputRef.current.value}`)
									}
									else {
										alert('prompt is a bit shallow')
									}
								}} style={{
									width: '200px',
									marginTop: '10px',
									height: '60px',
									backgroundColor: 'var(--qtheme-color-secondary)'
								}} > Generate Art </Button>
							</div>

							<Box
								display="flex"
								sm-flex-direction="column"
								sm-width="100%"
								sm-text-align="center"
								justify-content="center"
								align-items="center"
							>

							</Box>
						</Box>
					</div>
					<ImageSlider width={'100%'} height={"50%"} images={images} />
					<div>
					</div>
				</div>


			</Section>
			<Section overflow='hidden' min-height="100vh" padding="0 0 0 0" margin="100px 0px 0px 0px">
				<div style={isMobile?{}:{
					display: 'grid', gridTemplateColumns: '1fr 1fr'
				}}>
					<div  >
						<Override
							slot="SectionContent"
							flex-direction="row"
							flex-wrap="wrap"
							max-width="none"
							width="100%"
							sm-padding="72px 0px 0px 0px"
						/>
						<Box
							display="flex"
							width="40%"
							flex-direction="column"
							justify-content="center"
							align-items="flex-start"
							lg-width="100%"
							lg-align-items="center"
							sm-margin="0px 0px 50px 0px"
							margin="40px 5% 40px 5%"
							lg-padding="24px 60px 24px 60px"
							sm-padding="0px 20px 0px 20px"
							padding="24px 0px 24px 0px"
						>
							<Text
								margin="0px 0px 24px 0px"
								color="--secondary"
								font="normal 300 30px/1.5 --fontFamily-mono"
								lg-text-align="center"
								sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
								md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
							>
								<Span
									color="--secondary"
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									GENERATE ART
								</Span>
								{" "}with your description
							</Text>
							<Text margin="0px 0px 40px 0px" color="white" font="--lead" lg-text-align="center">
								All your walls want is {" "}
								<Span color="--secondary">
									AI ART
								</Span>
								{" "},Quickly describe the art you want, and our algorithm will automatically design a visual piece of art you can hang on your walls or share.
							</Text>
						</Box>
					</div>

					<ImageSlider width={'75%'} height={'100%'} images={imagesForLandscape} />
				</div>


			</Section>
			<Section padding="80px 0 80px 0">
				<Box align-items="center" display="flex" justify-content="center" flex-direction="column">
					<Text
						margin="0px 0px 0px 0px"
						font="--headline1"
						color="--color-secondary"
						sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						text-align="center"
					>
						Buy awesome ART
					</Text>
					<Text
						margin="25px 0px 0px 0px"
						font="--lead"
						display="block"
						text-align="center"
						color="--light"
						lg-width="100%"
						width="60%">
						Dont have any art idea in mind?
						<br />
						Try this prompts then
					</Text>

					<PinterestLayout></PinterestLayout>

				</Box>


			</Section>
			<Section background="--color-darkL1" padding="80px 0 80px 0">
				<Box
					display="flex"
					align-items="center"
					flex-direction="column"
					justify-content="center"
					padding="0px 200px 0px 200px"
					lg-padding="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 30px 0px"
						font="--headline1"
						color="--light"
						text-align="center"
						sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Contacts{"  "}
					</Text>
					<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
						We love what we do , if you wanna say hi or a complaint do be stranger reach out to us.
					</Text>
					<Link
						href="mailto:blank?hello@company.com"
						text-align="center"
						color="--light"
						font="--headline3"
						text-decoration-line="initial"
						margin="0px 0px 16px 0px"
					>
						el@beansio.app
					</Link>
					<SocialMedia facebook="https://www.facebook.com/quarklyapp/" twitter="https://twitter.com/quarklyapp" instagram="https://instagram.com/instagram" margin="0px 0px 0px 0px">
						<Override
							slot="link"
							border-radius="50%"
							color="--grey"
							hover-color="--light"
							background="#191E22"
							hover-background="--color-primary"
							margin="0 5px 0 5px"
							padding="5x 5px 5px 5px"
							width="48px"
							height="48px"
							align-items="center"
							display="flex"
							justify-content="center"
							hover-transition="background-color 0.2s ease-in-out 0s"
							transition="background-color 0.2s ease-in-out 0s"
						/>
						<Override
							slot="icon"
							color="--light"
							padding="7px 7px 7px 7px"
							border-width="0px"
							size="38px"
							border-radius="50px"
						/>
					</SocialMedia>
				</Box>
			</Section>
		</div>
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			{/* Made on Quarkly */}
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6370fbf7f45b7e00247b2e46"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
}

export default Component
const man_in_the_wind_1 = require('./1.png').default
const man_in_the_wind_2 = require('./2.png').default

const man_in_the_wind_3 = require('./3.png').default
const man_in_the_wind_4 = require('./4.png').default

const man_in_the_wind_5 = require('./5.png').default
const man_in_the_wind_6 = require('./6.png').default


const man_in_the_wind_7 = require('./7.png').default
const man_in_the_wind_8 = require('./8.png').default

const man_in_the_wind_9 = require('./9.png').default
const man_in_the_wind_10 = require('./10.png').default


const man_in_the_wind_11 = require('./11.png').default
const man_in_the_wind_12 = require('./12.png').default




export default [
    man_in_the_wind_1, man_in_the_wind_2,
    man_in_the_wind_3, man_in_the_wind_4,
    man_in_the_wind_5, man_in_the_wind_6,
    man_in_the_wind_7, man_in_the_wind_8, man_in_the_wind_9,
    ,
    man_in_the_wind_10, man_in_the_wind_11
    , man_in_the_wind_12
]
import React from 'react'
import styled, { keyframes } from 'styled-components';
import { getRandomImage } from "config/images";

const imagesPop = [
  {
    name:'John wick',
    image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/169sb2gy7q/2043018697.png'}
    ,{
      name:'a man creating the universe #pixelart',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/1ke84eojt1/final.png'
    },{
      name:'a man making art',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/1nssou7qibk/985269553.png'
    },{
      name:'a pig becoming friends with a bird',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/279iegzrfi/781612311.png'
    },{
      name:'a mad man',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/2ftmif4bmh/320975890.png'
    },{
      name:'a dog',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/2mjdcfm3r4j/523778344.png'
    },{
      name:'a king in black',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/3lxezrt8gt/1647515588.png'
    },{
      name:'a man making the universe',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/3sqqh5nk5n/108787572.png'
    },{
      name:'a robot making another robot #pixelart',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4fehy69g6l/final.png'
    },{
      name:'mature dogs',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4nai4gubo3/final.png'
    },{
      name:'a man making the universe',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4ttciebc0m/1072776883.png'
    },{
      name:'a man in his feelings',
      image:'https://storage.googleapis.com/dripple-183008.appspot.com/outputs/5e4b3u1row/538063826.png'
    }
]


const heartbeat = keyframes`
  0% {
    transform: scale(0.8);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.8);
  }
`;

const ImageContainer = styled.div`
text-align: center;
display: block;
background: white;
padding-top: 12px;
border-radius: 5px;
font-size: 20px;
  justify-content: center;
    align-items: center;
    /* height: 100vh; */
    max-width: 80%;
    margin: auto;
    margin-top: 31px;
    transform: scale(0.01);
    animation-name: ${heartbeat};
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
`;

const Image = styled.img`
max-width: inherit;

`;

const Component = styled.div`
  text-align:left;
  font-family: monospace;

  .prompt-creation{
    text-align: center;
    margin:auto;
  }

  .prompt-creation .h1{
    color: white;
    font-size: 38px;
    margin-top: 0;
  }

  .prompt-creation .h6{
    color: white;
    font-size: 18px;
    text-align:left
  }

  .prompt-creation .prompt{
    display:grid;
    grid-template-columns: 1fr 100px;
    justify-content: center;
  }

  input {
    height:45px;
    border:0;
    padding-left:20px;
    font-family: monospace;
  }

  .prompt-creation button {
    margin-left:10px;
    border:0;
    font-size:15px;
    font-family: monospace;
  }

  .prompt{
    border-radius: 8px;
    overflow: hidden;
    background:white;
    border:0
  }

  .prompthome{
    border:1px solid white;
    padding:10px;
    border-radius: 8px;
  }

  .style-choosing{
   }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
      gap: 40px 10px;
    }

    .grid-container > div {
      background-color: rgba(255, 255, 255, 0.8);
      text-align: center;
      width:190px;
      height:190px;
      font-size: 30px;
      position:relative
    } 

    .grid-container  p {
      position:absolute;
      bottom:-47px;
      left:0px;
      background:white;
      width:190px;
      font-size:20px
     } 

 
     .preview{
      display:grid;
     }

     .preview .image{
      border-radius: 10px;
     }

     .selected{
      color:white;
      background: #181818 !important;
     }
`;


export default function StepStart({ move }) {
  const [text, setText] = React.useState({})
  const [img, setImg] = React.useState('test6')
  const [selected, setSelected] = React.useState({})

  const [animated, setAnimated] = React.useState(false);

  const handleClick = () => {
    setAnimated(true);
    setTimeout(() => setAnimated(false), 3000); // set animation class back to false after 3 seconds
  };

  React.useEffect(()=>{
    const randomElement = imagesPop[Math.floor(Math.random() * imagesPop.length)];
    setSelected(randomElement)
    const interval = setInterval(()=>{
      ///get random index
      const randomElement = imagesPop[Math.floor(Math.random() * imagesPop.length)];
      console.log({
        randomElement
      })
      setSelected(randomElement)
    },5000)
    return  ()=>{
      clearInterval(interval)
    }
  },[])


  return (
    <Component>
      <div className='main'>
        {/* <div>
          <textarea onChange={e=>{
                setText(e.target.value)
          }} placeholder='Illustration of Korean Joseon Dynasty'></textarea>
          <svg onClick={e=>{
            move(text)
          }} width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="69" height="69" rx="34.5" fill="white" stroke="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.5318 23.2605C34.9623 22.691 34.039 22.691 33.4695 23.2605C32.8999 23.83 32.8999 24.7534 33.4695 25.3229L41.1883 33.0417H24.2923C23.4869 33.0417 22.834 33.6946 22.834 34.5C22.834 35.3055 23.4869 35.9584 24.2923 35.9584H41.1883L33.4695 43.6772C32.8999 44.2467 32.8999 45.1701 33.4695 45.7396C34.039 46.3091 34.9623 46.3091 35.5318 45.7396L45.7402 35.5312C46.3097 34.9617 46.3097 34.0384 45.7402 33.4688L35.5318 23.2605Z" fill="black"/>
</svg>

        </div>
        <img src='https://storage.googleapis.com/dripple-183008.appspot.com/outputs/gsnhlzbl2v/final.png'></img> */}

        <div className="prompt-creation">
          <p className="h1">
            Hello, start making AI art now
          </p>

          <div className="prompthome">
            <div className="prompt">
              <input onChange={e => {
                setText(e.target.value)
              }} type="text" placeholder='type in AI art prompt' /> <button onClick={e => {
                move({
                  text
                 })
              }}>
                Create
              </button>
            </div>
          </div>

          <div className='style-choosing' >
            <p className="h6" style={{
              textAlign:'center'
            }}>
              Some pictures made by ai
            </p>

           <div className="preview">

            <ImageContainer>
              <Image src={ selected.image} ></Image>
              <p>
                {selected.name}
              </p>
            </ImageContainer>
               
               {/*  <div class="grid-container">
                {[{
                  img: require('../../assets/test6_normal.png'),
                  id: '9k01utaqmw',
                  text: 'Basic',
                  style: 90
                },
                {
                  img: require('../../assets/cat-pixel_qul8o6c54ui.png'),
                  id: 'qul8o6c54ui',
                  text: 'Pixel art',
                  style: 1
                },
                {
                  img: require('../../assets/cat_8k_4xvn25tmzs.png'),
                  id: '4xvn25tmzs',
                  text: '8K HD',
                  style: 0
                },
                {
                  img: require('../../assets/cat_matt_ima1rk1ecd.png'),
                  id: 'ima1rk1ecd',
                  text: 'Matte drawing',
                  style: 3
                },
                {
                  img: require('../../assets/cat_charcoal_gyiyk3prm2.png'),
                  id: 'gyiyk3prm2',
                  text: 'Charcoal drawing',
                  style: 4
                },
                {
                  img: require('../../assets/cat_art_station_rq57fgj8ks.png'),
                  id: 'rq57fgj8ks',
                  text: 'Artstation',
                  style: 5
                }, {
                  img: require('../../assets/cat_cry_mbgd91cysl.png'),
                  id: 'mbgd91cysl',
                  text: 'Cry Engine',
                  style: 7
                },
                {
                  img: require('../../assets/cat_unreal_9k01utaqmw.png'),
                  id: '9k01utaqmw',
                  text: 'Unreal Engine',
                  style: 2
                }
                ].map(({ img, text, id, style }) => {
                  return <div onClick={e => {
                   
                  }} style={{ margin:'auto',cursor: 'pointer', backgroundSize: 'cover', backgroundImage: 'url(' + img.default + ')' }} class="item1">
                     
                  </div>
                })}
              </div> */}

              {/* <div className="image" style={{ backgroundSize:'cover', backgroundImage: 'url(' + require(`../../assets/${img}.png`).default + ')' }}  ></div> */}
            </div>
          </div>
        </div>
      </div>
    </Component>

  )
}

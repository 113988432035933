import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';

import { getFirestore } from "firebase/firestore";

const firebaseConfig ={
    apiKey: 'AIzaSyD_YpZGwZCM3Duz20kbTwATWrlRg4y5ip4',
    authDomain: 'dripple-183008.firebaseapp.com',
    databaseURL: 'https://dripple-183008.firebaseio.com',
    client_secret: 'LerCaSjOzPEUsDW0ItbeW-er',
    projectId: 'dripple-183008',
    storageBucket: 'dripple-183008.appspot.com',
    messagingSenderId: '1078684617310',
    client_id:
      '1078684617310-4qfk36jf5iblfsp5k273nm56n0r5g5ar.apps.googleusercontent.com',
    appId: '1:1078684617310:web:c296806a345c9bac3ec2cf',
    REACT_APP_GOOGLE_CLIENT_ID:
      '221826381676-eeh5d1fhkkk3do0njt9hn2crrcfuvrdj.apps.googleusercontent.com',
  }

// Initialize Firebase 
const app = firebase.initializeApp(firebaseConfig);
 const storageRef = firebase.storage().ref()
export const listRef = storageRef.child('outputs')
export const auth = firebase.auth();
export  const db = getFirestore(app);
 
export const storage = firebase.storage(app);
export const  getImageUrl = async (link)=>{
  try {
    const imageRef = await firebase.firestore().collection("generated").doc(link).get()
    
    return imageRef.data()
  } catch (error) {
    return false
  }
};


firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    console.log('User is authenticated:', user);
    // Add code here to access the 'outputs' folder
  } else {
    console.log('User is not authenticated');
    // Add code here to handle unauthenticated user
  }
});
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

// export const signInWithGoogle = () => auth.signInWithPopup(provider).then((result) => {
//   // This gives you a Google Access Token. You can use it to access the Google API.
//   const {credential,user}  = result
//   const token = credential.accessToken;
  
//   window.localStorage.setItem('auth',{
//     user,token
//   })

//   window.location.href = '/app'

//   //open page

//   // ...
// }).catch((error) => {
//   console.log(error)
//   // Handle Errors here.
//   // const errorCode = error.code;
//   // const errorMessage = error.message;
//   // // The email of the user's account used.
//   // const email = error.customData.email;
//   // // The AuthCredential type that was used.
//   // const credential = GoogleAuthProvider.credentialFromError(error);
//   // ...
// });;

export const signInWithGoogle = () =>  window.location.href = '/app'

export default firebase
const man_in_the_wind_1 = require('./man_in_the_wind_1.png').default
const man_in_the_wind_2 = require('./man_in_the_wind_2.png').default

const man_in_the_wind_3 = require('./man_in_the_wind_3.png').default
const man_in_the_wind_4 = require('./man_in_the_wind_4.png').default

const man_in_the_wind_5 = require('./man_in_the_wind_5.png').default
const man_in_the_wind_6 = require('./man_in_the_wind_6.png').default


const man_in_the_wind_7 = require('./man_in_the_wind_7.png').default
const man_in_the_wind_8 = require('./man_in_the_wind_8.png').default

const man_in_the_wind_9 = require('./man_in_the_wind_9.png').default
const man_in_the_wind_10 = require('./man_in_the_wind_10.png').default


const man_in_the_wind_11 = require('./man_in_the_wind_11.png').default
const man_in_the_wind_12 = require('./man_in_the_wind_12.png').default

const a_man_fighting_for_space = require('./a_man_fighting_for_space.png').default

 

export default [
    {
    image:man_in_the_wind_1,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:man_in_the_wind_2,
    prompt:"Man in the wind",
    size: 'a3'
},
{
    image:man_in_the_wind_3,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:man_in_the_wind_4,
    prompt:"Man in the wind",
    size: 'a3'
},
{
    image:man_in_the_wind_5,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:man_in_the_wind_6,
    prompt:"Man in the wind",
    size: 'a3'
},
{
    image:man_in_the_wind_7,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:man_in_the_wind_8,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:man_in_the_wind_9,
    prompt:"Man in the wind",
    size: 'a3'
},
{
    image:man_in_the_wind_10,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:man_in_the_wind_11,
    prompt:"Man in the wind",
    size: 'a3'
}
,{
    image:man_in_the_wind_12,
    prompt:"Man in the wind",
    size: 'a3'
},{
    image:a_man_fighting_for_space,
    prompt:"a man fighting for space",
    size: 'a3'
}
]
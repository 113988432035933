import React from "react";
import Index from "pages/index";
// import App from "pages/app";
import App from "pages/nonAuthApp";
import Header from './components/header'
import Page404 from "pages/page404";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "pages/login";
import allImages from 'pages/allImages'
import Draw from 'pages/draw'
import { isAuthenticated } from "services/auth";
// CALL IT ONCE IN YOUR APP
 // CALL IT ONCE IN YOUR APP
// import "!style-loader!css-loader!react-toastify/dist/ReactToastify.css"
///smart
const GlobalStyles = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }
`;


const PrivateRoute = (Component, lost = false) => {
    const component = ()=>{
        return <Header>
             <Component></Component>
        </Header>
    }

    
    const redirectComponent = () =>{
        return  <Redirect  to='/' />
    }
    
    if (typeof window !== "undefined") {
        if (isAuthenticated()) {
            return    component
        }else{
            return redirectComponent
        }
    }
    return  'hi'
}


const UnPrivateRoute = (Component, redirect=false,lost = false) => {
    const component = ()=>{
        return <Header>
             <Component></Component>
        </Header>
    }

    const redirectComponent = () =>{
        return  <Redirect  to='/app' />
    }
    
    if (typeof window !== "undefined") {

        if (isAuthenticated() && redirect) {  
            ///means i only want this page accessible when am not logged in 
            return redirectComponent
        }
        return    component
    }
    return  'hi'
}


 const AppCompoent = () => {

    // injectStyle();
console.log('isAuthenticated',isAuthenticated())

    return   <Router>
    <GlobalStyles />
    <ToastContainer />

    <Switch>
        <Route exact path='/' component={Index} />
        {/* <Route exact path='/app' component={PrivateRoute(App)} /> */}
        <Route exact path='/app' component={UnPrivateRoute(App)} />
        <Route exact path='/draw/:id' component={UnPrivateRoute(Draw)} />
        <Route exact path='/allimages' component={UnPrivateRoute(allImages)} />
        <Route exact path='/login' component={UnPrivateRoute(Login,true)} />
        <Route
            exact
            path="*"
            component={PrivateRoute(Index, true)}
        />
        {/* <Route exact path='/app' component={PrivateRoute(App)} />
        <Route exact path='/login' component={UnPrivateRoute(Login,true)} />
         <Route exact path='/index' component={Index} />
        <Route
            exact
            path="*"
            component={PrivateRoute(Index, true)}
        /> */}
        <Route component={Page404} />
    </Switch>
</Router>
}

export default AppCompoent

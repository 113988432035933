import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Span, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { useHistory } from "react-router-dom";



const animateMyKids = (play) => {
	// collect all the divs
	var divs = document.getElementsByClassName(play + 'kid');

	// i stands for "index". you could also call this banana or haircut. it's a variable
	for (var i = 0; i < divs.length; i++) {

		// shortcut! the current div in the list
		var thisDiv = divs[i];

		// get random numbers for each element
		const randomTop = getRandomNumber((document.getElementById('play').offsetTop - document.getElementById('play').offsetHeight), (document.getElementById('play').offsetTop));
		const randomLeft = getRandomNumber(document.getElementById('play').offsetLeft, document.getElementById('play').offsetWidth);

		// update top and left position
		thisDiv.style.top = randomTop + "px";
		thisDiv.style.left = randomLeft + "px";

	}

	// function that returns a random number between a min and max
	function getRandomNumber(min, max) {

		return Math.random() * (max - min) + min;

	}
}
const Component = ({ children }) => {

	const history = useHistory()

	React.useEffect(() => {

		setTimeout(() => {
			animateMyKids('play')
		}, 100)

		setInterval(() => {
			animateMyKids('play')
		}, 4000)

	}, [])

	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title >
				Beans Art
			</title>
			<meta name={"description"} content={"Make amazing images "} />
			{/* <link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} /> */}
		</Helmet>
		<Section background="--color-primary" border-width={0}>
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
			>
				<Text
					onClick={e => {
						history.push('/')
					}}
					color="--light"
					margin="0"
					md-margin="0px 0 20px 0"
					text-align="left"
					font="--lead"
					cursor="pointer"
				>
					BeansIo
				</Text>
			</Box>
		</Section>

		<div style={{
			maxWidth:'90vw',
			margin:'auto'
		}}>
		{children}
		</div>

	</Theme>;
};

export default Component
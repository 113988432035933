/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { updateSession } from 'services/api';
import styled from 'styled-components';

const ExitComponentStyle = styled.form`
.success-page{
 
    text-align: center;
    width: 100%;
    background: white;
    padding: 40px 0;
  }
  .success-page img{
    max-width:62px;
    display: block;
    margin: 0 auto;
  }
  
  .btn-view-orders{
  border:1px solid #666EE8;
    display: block;
    width: fit-content;
    margin: 0 auto;
    cursor:pointer;
    margin-top: 45px;
    padding: 10px;
    color: #fff;
    background-color: #666EE8;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin-bottom: 20px;
  }
  h2{
    color:#666EE8;
      margin-top: 25px;
  
  }
  a{
    text-decoration: none;
  }
`;

const ExitComponent = ({ paymentStatus, goBack }) => {

    React.useEffect(() => {
        updateSession(window.location.pathname.split('/')[2], { paymentStatus })

        setTimeout(() => {
            // if (paymentStatus) {
            //     window.location.href = '/'
            // } else {
            //     goBack()
            //     ///go back to the payment page
            // }

        }, 5000);
    })
    return <ExitComponentStyle  className='main'>

        {paymentStatus ? <div>
            <div class="success-page">
                <img src="http://share.ashiknesin.com/green-checkmark.png" class="center" alt="" />
                <h2>Payment Successful !</h2>
                <p>We are delighted to inform you that we received your payments</p>
                <a onClick={() => {
                    window.location.href = '/'
                }} class="btn-view-orders">Go back to landing page?</a>
            </div>
        </div> : <div>
            <div class="success-page">
                <img src="http://share.ashiknesin.com/green-checkmark.png" class="center" alt="" />
                <h2>Payment Failed !</h2>
                <p>We tried making a payment but we seem to be having an issue</p>
                <a onClick={() => {
                    goBack()
                }}   class="btn-view-orders">Try again</a>
            </div>
        </div>
        }

    </ExitComponentStyle >
}

export default ExitComponent
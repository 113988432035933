import { listRef } from 'config/firebase';
import React, { useState, useEffect } from 'react';
import { getStorage, ref, list } from "firebase/storage";
import Modal from 'react-modal';


import styled from 'styled-components'

const Component = styled.div`
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-container {
  width: calc(33.33% - 10px);
  margin-bottom: 20px;
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease-in-out;
}

.image:hover {
  transform: scale(1.05);
}

.download-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.download-button:hover {
  transform: scale(1.1);
}

 


`;
function AllImages() {
  const [imageUrls, setImageUrls] = useState([]);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const urls = [];
    let timeout = false;

    const storage = getStorage();
    listRef.listAll()
      .then((res) => {
        res.prefixes.forEach((prefixRef) => {
          const subDirRef = ref(storage, prefixRef.fullPath);

          list(subDirRef)
            .then((res) => {
              res.items.forEach((itemRef) => {
                if (itemRef.name.endsWith('.mp4')) {
                  return;
                }

                const url = `https://storage.googleapis.com/dripple-183008.appspot.com/${prefixRef.fullPath}/${itemRef.name}`;
                urls.push(url);
                if (timeout) {
                  clearTimeout(timeout);
                }
                timeout = setTimeout(() => {
                  setImageUrls(urls);
                }, 5000);
              });
            })
            .catch((error) => {
              console.error("Error listing images in subdirectory:", error);
            });
        });
      })
      .catch((error) => {
        console.error("Error listing files and directories:", error);
      });
  }, []);

  const handleImageClick = (url) => {
    setPreviewImageUrl(url);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  return (
    <Component>
       <div className="image-grid">
      {imageUrls.map((url) => (
        <div className="image-container" key={url}>
          <img src={url} alt="Image" onClick={() => handleImageClick(url)} />
          <a href={`http://ec2-54-227-133-83.compute-1.amazonaws.com/optemize?url=${url}`} download>
            <button className="download-button">Download</button>
          </a>
        </div>
      ))}

      <Modal  className="modal-content"     
 isOpen={isModalOpen} onRequestClose={handleModalClose}>
        <img   className="modal-image"  src={previewImageUrl} alt="Preview" />
      </Modal>
    </div>
    </Component>
     
  );
}

export default AllImages;

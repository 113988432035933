import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { setUpChargeId } from 'services/api';
import styled from 'styled-components';


const CardPaymentHolder = ({ setLoader }) => {
  return <CardElement onReady={() => {
    setLoader(false)
  }} />
}


const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 10px;
`;

const Label = styled.label`
  
    width: 100%;
    font-size: 18px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 125px 1fr;
    font-family: "Helvetica Neue", Helvetica;
    font-size: 15px;
    font-variant: normal;
    color:#8898AA;
    border-bottom:1px solid #F0F5FA

`;

const Input = styled.input`
  padding: 5px;
  margin-top: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 0;
`;

const Button = styled.button`
  float: left;
  display: block;
  background: #666EE8;
  color: white;
  box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 0;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 40px;
  line-height: 38px;
  outline: none;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 10px;
`;

const CardContainer = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow:0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
  width: inherit;
`;

const CheckoutForm = ({move,sessionId}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [line1, setLine1] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [loader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (elements == null) {
      return;
    }

    try {

      console.log(elements)
      setLoader(true)
      const charge = await setUpChargeId(stripe, {
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: name,
          email: email,
          phone: phone,
          address: {
            line1: line1,
            postal_code: postalCode,
            state: state,
            city: city
          },
        },
      },sessionId  , setErrorMessage)

      console.log({charge})

      if (!charge) {
        throw new Error('cant finalise payments')
      }
      setLoader(false)
      move(true)
    } catch (error) {
      setLoader(false)
      move(false)
      console.log(error)
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit} >
      <CardContainer>
        <Label>
          <p>
            Name:
          </p>
          <Input placeholder='John Doe' type="text" value={name} onChange={e => setName(e.target.value)} required  disabled={loader}/>
        </Label>
      </CardContainer>

      <CardContainer>
        <Label>
          <p>
            Email:
          </p>
          <Input placeholder='buy_my@art' type="email" value={email} onChange={e => setEmail(e.target.value)} required disabled={loader}/>
        </Label>
        <Label>
          <p>
            Phone:
          </p>
          <Input placeholder='+1 909042432' type="tel" value={phone} onChange={e => setPhone(e.target.value)} required disabled={loader}/>
        </Label>
      </CardContainer>

      <CardContainer>
        <Label>
          <p>
            Address:
          </p>
          <Input placeholder='123 Road to your heart' type="text" value={line1} onChange={e => setLine1(e.target.value)} required disabled={loader}/>
        </Label>
        <Label>
          <p>
            Postal Code:
          </p>
          <Input placeholder="postal code" type="text" value={postalCode} onChange={e => setPostalCode(e.target.value)} required disabled={loader}/>
        </Label>
        <Label>
          <p>
            State:
          </p>
          <Input placeholder='state ' type="text" value={state} onChange={e => setState(e.target.value)} required disabled={loader}/>
        </Label>
        <Label>
          <p>
            City:
          </p>
          <Input placeholder='city of your heart' type="text" value={city} onChange={e => setCity(e.target.value)} required disabled={loader}/>
        </Label>


      </CardContainer>

      <CardContainer style={{
        maxWidth:'400px',
        width:'100%'
      }} >
        <CardPaymentHolder setLoader={setLoader} />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button  disabled={loader} >{!loader?"Pay Now":'Loading'}</Button>
      </CardContainer>


    </FormContainer>
  );
};







const stripePromise = loadStripe('pk_test_51Mo35QBi9iPJbTuLAvSqDy0t4fSbJ5ENL7oaaAnWnWtbgjcNlevAPSXGMouClDrclLsCJTv7pkbbe2d6hXmqmAcA00pEjIJfcW');


const PayApp = ({move,sessionId}) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm move={move} sessionId={sessionId}/>
  </Elements>
);

export default PayApp
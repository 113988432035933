import React from 'react'
import { markActivated, startProcess, updateSession } from 'services/api'
import { useLocation } from 'react-router-dom'
import StepCreatePrompt from './steps/stepCreatePrompt'
import styled from 'styled-components'
import WaitLoading from './steps/stepWaitLoading'
import { setImage, setToPending, waitForImages } from 'services/firebase'
import SelectImage from './steps/selectImage'
import SelectFrame from './steps/stepSelectSize'

import PayApp from './steps/pay'
import QRCode from 'react-qr-code'

const Component = styled.div`
  text-align:left;

  .done-message{
    color: white;
    font-size: 38px;
    margin-top: 0;
    text-align:center;
    margin-top:60px
  }
`;


const ExitComponent = ({ paymentStatus, goBack }) => {

  React.useEffect(() => {
    updateSession(window.location.pathname.split('/')[2], { paymentStatus })

    setTimeout(() => {
      if (paymentStatus) {
        window.location.href = '/'
      } else {
        goBack()
        ///go back to the payment page
      }

    }, 5000);
  })
  return <div>

    <p className='done-message' >
      {paymentStatus ? "Done making images, lets head back to the big screen" : 'Failed payments try again'}
    </p>

  </div>
}

function Draw() {
  const location = useLocation();

  const [steps, setSteps] = React.useState(-1)
  const [form, setForm] = React.useState({})
  const [message, setMessage] = React.useState('Loading art engine ')



  React.useEffect(() => {

    if (steps === 0) {
      ///wait for images to be loaded
      setForm((form) => {
        return {
          ...form,
          canPay: false
        }
      })
      waitForImages(location.pathname.split('/')[2], (images) => {
        console.log(images)
        if (!images.images) {
          setForm((form)=>{
            return {
              ...form,
              images
            }
          })
          setSteps(3)
        } else {
          
          ///should be an object 
          if (images.images) {
            setForm((form)=>{
              return {
                ...form,
                images: images.images,
              }
            })
          }

          if (images.done) {

            console.log({
              ...form,
              images: images.images,
              canPay: true
            })

            setForm((form) => {
              return {
                ...form,
                images: images.images,
                canPay: true
              }
            })

          }
        }

      },
        () => {
          alert('Sorry failed to generate images taking you back to the beginning ')
          setSteps(0)
          ///failed process
        })
    }




  }, [steps])



  React.useEffect(() => {
    markActivated(location.pathname, () => {
      ///success

      setSteps(0)
    }, () => {
      ///fail
      setMessage({fail:'Sorry but is seems your session is invalid please go and scan new QR-CODE'})
    })
    ///wait for images to be loaded


    ///set up timer to listen for when this session ends 
    const interval = setInterval(() => {
       ///pings the server every 30 seconds and remind it that am still alive
      setImage(window.location.pathname.split('/')[2], {
        ping: Math.random()
      })
    }, 5000);

    return ()=>{
      clearInterval(interval)
    }
  }, [])



  return (
    <Component>
      {steps === -1 && <WaitLoading message={message} />}


      {steps === 0 && <StepCreatePrompt images={form.images || []} move={({ text }) => {
        setSteps(1)
        setForm({
          ...form,
          prompt: text
        })

      }} />}

      {steps === 1 && <SelectFrame move={frameId => {


        setForm({
          ...form,
          frameSize: frameId
        })
        setSteps(2)
        startProcess({
          ...form,
          frameSize: frameId,
          session: location.pathname.split('/')[2]
        }, setForm)




      }} images={form.images || []} />}

      {steps === 2 && <WaitLoading message='Dreaming about art 😴' />}

      {steps === 3 && <SelectImage canPay={form.canPay} setImage={(viewImage) => {
        setImage(location.pathname.split('/')[2], { viewImage })
      }} images={form.images || []} move={async (direction) => {
        if (direction === '__BACK__') {
          ///got back to the begining
          await setToPending(location.pathname.split('/')[2])
          setSteps(0)
          return
        }
        //moves to the frames
        setSteps(4)
      }} />}


      {steps === 4 && <PayApp sessionId={location.pathname.split('/')[2]} move={sucess => {
        if (sucess) {
          setForm((form) => {
            setImage(location.pathname.split('/')[2], { donePayment: true })
            return {
              ...form,
              paymentStatus: sucess
            }
          })
        }
        setSteps(5)
      }} images={form.images || []} />}

      {steps === 5 &&

        <ExitComponent goBack={e => {
          setSteps(4)
        }} paymentStatus={form.paymentStatus} />

      }


      {/* {steps === 2 && <ImagePreview setImage={image => {
        setForm({
          ...form,
          selectedImage: image
        })

        setSteps(3)
      }} images={form.images || []} />}

      {steps === 3 && <FrameSize move={frameId => {
        setForm({
          ...form,
          frameId
        })
        setSteps(4)
      }} images={form.images || []} />}

      {steps === 4 && <PayApp move={frameId => {
        setForm({
          ...form,
          frameId
        })
        setSteps(4)
      }} images={form.images || []} />} */}



    </Component>

  )
}

export default Draw
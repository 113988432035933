const man_in_the_wind_1 = require('./p1.png').default
const man_in_the_wind_2 = require('./p2.png').default

const man_in_the_wind_3 = require('./p3.png').default
const man_in_the_wind_4 = require('./p4.png').default

const man_in_the_wind_5 = require('./p5.png').default 


export default [
    man_in_the_wind_1, man_in_the_wind_2,
    man_in_the_wind_3, man_in_the_wind_4,
    man_in_the_wind_5
]
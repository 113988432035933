const arrIMages =  [
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/2wt1t5815a/501929826.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/5gsgvqlo7e/1683044532.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4nduvjq605h/1667393041.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4b39y598fzi/115757727.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/1nssou7qibk/985269553.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4j65el1n2q/1870806841.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/4j65el1n2q/2101700313.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/5fn07jnmtp/1004814037.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/3lxezrt8gt/1647515588.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/7dsu/1261638156.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/7dsu/1261638158.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/7dsu/1380818150.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/7dsu/2025978851.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/7dsu/672940626.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/dd9h53jvjn/530328824.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/jww7jmylpi/666485333.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/jwkcfm9jh/1854024828.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/l1ny9s9afa/1934984001.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/lw2qz8xpa1/1114408562.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/s0mvguc516/140637479.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/t57x6cpva7i/937850105.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/uiuiu/2.png",
     "https://storage.googleapis.com/dripple-183008.appspot.com/outputs/vmdk52l6k4/511157801.png",
     
    ]

export const getRandomImage = ()=>{
    return  arrIMages[Math.floor(Math.random()*arrIMages.length)];
}


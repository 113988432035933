import React from 'react'
import styled from 'styled-components'


const Button = styled.button`
  
    display: block;
    cursor:pointer;
    background: #666EE8;
    color: white;
    box-shadow: 0 7px 14px 0 rgba(49,49,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.08);
    border: 0;
    margin-top: 20px;
    font-size: 25px;
    font-weight: 400;
    width: 100%;
    height: 40px;
    line-height: 38px;
    outline: none;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;




  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;


const Component = styled.div`

.h1{
    color: white;
    font-size: 38px;
    margin-top: 0;
    text-align:center
  }

  .backgound-home{
    background: url("https://officedepot.scene7.com/is/image/officedepot/living_room_background") no-repeat;;
    min-height:436px;
    background-size: contain;
    height: 80vh;
    background-size: cover;
    
  }

.frames-picker{
    display: grid;
    grid-template-columns: 1fr 400px;
}
 

.item{
    width: 270px;
    display: inline-grid;
    border-radius: 10px;
    background: white;
}

.item img{
    width:inherit;
    height:inherit;
}

.item .below{
    padding: 0 10px;
    text-align: right;
    font-size: 22px;
    color: green;
    font-family: monospace;
}

.furnitures{
    display: grid;
    grid-template-columns: 357px 1fr;
    height: 100%;
}

.furnitures .frames{
    display: flex;
}

.furnitures .frames .frame div{
    margin: auto;
    border: 1px solid rgba(30,90,153,.2);
    background-color: #f5f8fc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top:20px;
    border-radius:4px;
    background-size:contain !important;
}

.furnitures .frames .frame div{
    font-size: 12px;
    display: flex;
    align-self: center;
    padding: 2px;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif!important;
}


.frame-control{
    background:white;
    padding:10px 20px;
}


.frame-item{
    text-align: center;
    display: flex;
    cursor:pointer;
    justify-content: space-between;
    font-weight: 600;
    text-align: center;
    font-size: 13px;
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif!important;
}

.frame-control  .prices .selected{
    border: 1px solid rgba(30,90,153,.2);
    background-color: #f5f8fc;
    padding: 0px 10px;
    border-radius: 4px;
}
 
.sizes{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    font-size: 15px;
    column-gap: 8px;
}

.sizes p{
    border: 1px solid rgba(30,90,153,.2);
    background-color: #f5f8fc;
    padding: 5px 10px;
    border-radius: 4px;
    cursor:pointer;
}

.sizes .selected{
    background-color: #666EE8 !important;
    color:white;
}




/* Mobile */
@media (max-width: 700px) {
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    background: #04080C;
    width: 100vw;
    padding-top: 13px;

    .frames-picker{
        display: grid;
        grid-template-columns: none;
        background:white;
        margin-bottom:40px;

     }

     .backgound-home{
        background: url("https://officedepot.scene7.com/is/image/officedepot/poster_sofa") center bottom / 280px 114px no-repeat;
        height:500px;
        background-size: 100%;
      }

      .door{
        display:none
      }

      .furnitures{
        grid-template-columns: none;
      }

      .frame{
      }

     .frames .selected{
        display:block !important;
        margin: 0 auto;
    }
    
   
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
    .frames-picker{
        display: grid;
         grid-template-columns: none;
        background:white;
        margin-bottom:40px;

     }

     .backgound-home{
        background: url("https://officedepot.scene7.com/is/image/officedepot/poster_sofa") center bottom / 280px 114px no-repeat;
        height:500px;
        background-size: 100%;
        min-height:800px;
      }

      .door{
        display:none
      }

      .furnitures{
        grid-template-columns: none;
      }

      .frame{
      }

     .frames .selected{
        display:block !important;
        margin: 0 auto;
    }
    
}




 

`

//0 is square
//1 is Portrait
//2 is WildScreen
const availableFrames = {
    '1': [
        {
            dimension: [16, 20],
            price: '50',
            id: 'a1',
        }, {
            dimension: [18, 24],
            price: '75',
            id: 'a2',
        }, {
            dimension: [24, 36],
            price: '99',
            id: 'a3',
        }
    ],
    '0': [
        {
            dimension: [20, 16],
            price: '50',
            id: 'b1',
        }, {
            dimension: [24, 18],
            price: '75',
            id: 'b2',
        }, {
            dimension: [36, 24],
            price: '99.99',
            id: 'b3',
        }
    ], '2': [
        {
            dimension: [18, 18],
            price: '60',
            id: 'c1',
        }, {
            dimension: [24, 24],
            price: '99',
            id: 'c2',
        }
    ],
}

function SelectFrame({ move, mode = 0, imageSelectIndex=0, images, selectedFrame , frameImage  }) {

    const [total, setTotaal] = React.useState(74)
    const [selected, setSelected] = React.useState(0)
    const [selectedSizes, setSelectedSizes] = React.useState(0)
    const [scale, setScale] = React.useState(0)
    const divRef = React.useRef(null);

    console.log({imageSelectIndex})

    React.useEffect(() => {
        if (divRef.current) {
          const width = divRef.current.offsetWidth;

          if (window.innerWidth >= 768) { // set breakpoint for desktop screens
            setScale(width/2 * 1); // set scale value for desktop screens
          } else { // set breakpoint for mobile screens
            setScale(width/2 * 3); // set scale value for mobile screens
          }
        }
      }, []);

    React.useEffect(() => {
        if (!selectedFrame) {
            return
        }
        if (mode === 1) {///this is an already made image preview
            ///get all frame dimanesions by frame id
            Object.keys(availableFrames).forEach((key, j) => {
                //gotten a key 
                availableFrames[key].forEach((data, i) => {
                    ///goten a frame
                    if (data.id === selectedFrame) {
                        setSelected(i)
                        setSelectedSizes(j)
                    }
                })
            })
        }
    }, [selectedFrame]);


    return (
        <Component>

            <p className="h1">
                Pick a frame
            </p>

            <div className="frames-picker"  ref={divRef} style={mode!==1 ? {} : {  gridTemplateColumns: '1fr' } } >
                <div className="backgound-home">
                    {/* / */}
                    <div className="furnitures" style={mode!==1 ? {} : {  gridTemplateColumns: '1fr' } }>
                        {mode !== 1 && <div className="door"></div>}
                        <div className="frames"   >
                            {availableFrames[`${selectedSizes}`].map(({ dimension,id }, i) => {
                                if (i !== selected) {
                                    return ''
                                }
                                let style = {
                                    width: `${((dimension[0] / total) * scale) }px`,
                                    height: `${((dimension[1] / total) * scale)}px`
                                }
                                if (mode === 1) {
                                    style = { ...style, background: frameImage? `url(${frameImage})` : `url(${images[`${imageSelectIndex}`]})`,backgroundSize:'contain' }
                                }
                                return  <div className={`frame`} style={{
                                    width: `${(dimension[0] / total) * scale}px`,
                                    maxHeight: `${(dimension[1] / total) * scale}px`,
                                    margin:' 0 auto'
                                }} >
                                    <div style={{ ...style }}>
                                        {mode !== 1 && <p>
                                            {`${dimension[0]}" x ${dimension[1]}"`}
                                        </p>}
                                    </div>
                                </div>  
                            })}
                        </div>
                    </div>
                </div>
                {mode!==1  && <div className="frame-control"   >

                    <div className="sizes">
                        <p onClick={e => {
                            setSelectedSizes(() => {
                                setSelected(0)
                                return 0
                            })
                        }} className={`${0 === selectedSizes ? 'selected' : ''}`} >LandScape</p>
                        <p onClick={e => {
                            setSelectedSizes(() => {
                                setSelected(0)
                                return 1
                            })
                        }} className={`${1 === selectedSizes ? 'selected' : ''}`} >Portrait</p>
                        <p onClick={e => {
                            setSelectedSizes(() => {
                                setSelected(0)
                                return 2
                            })
                        }} className={`${2 === selectedSizes ? 'selected' : ''}`} >Squaare</p>
                    </div>
                    <div className="prices">
                        {availableFrames[`${selectedSizes}`].map(({ dimension, price }, i) => {
                            return <div className={`frame-item ${i === selected ? 'selected' : ''}`} onClick={e => {
                                setSelected(i)
                            }} style={{

                            }} >
                                <p>  {`${dimension[0]}" x ${dimension[1]}"`} </p>

                                <p className="price">
                                    ${price}
                                </p>
                            </div>
                        })}
                    </div>

               
                </div>}

                {mode !== 1 ? <Button style={{
                        cursor:'pointer'
                    }} onClick={e => {
                        move(availableFrames[selectedSizes][selected].id)
                    }}>
                        Generate wall art!
                    </Button>   : <Button style={{
                        cursor:'pointer'
                    }} onClick={e => {
                        move(availableFrames[selectedSizes][selected].id)
                    }}>
                          Looking good
                    </Button>  }
            </div>
        </Component>
    )
}

export default SelectFrame
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import WaitLoading from './steps/stepWaitLoading';
import SelectFrame from './steps/stepSelectSize';
import { replicateService, replicateCheckService } from 'services/api';
import { useLocation } from 'react-router-dom';
import PayApp from './steps/pay';
import ExitComponent from './steps/exitPage';
const Component = styled.div`
  text-align: left;
`;


const replicateCall = async (setForm, data) => {
  const image = await replicateService(data)
  setForm((form) => {
    return {
      ...form,
      ...image
    }
  })
}


const replicateCallUpdate = async (id, setForm,sessionId) => {
  const { completed, data } = await replicateCheckService(id,sessionId)
  if (completed) {
    setForm((form) => {
      return {
        ...form,
        imageUrl: data
      }
    })
  } else {
    setTimeout(() => {
      replicateCallUpdate(id, setForm,sessionId)
    }, 10000)
  }
}



function App() {
  const [steps, setSteps] = useState(0);
  const [form, setForm] = useState({
    images: [],
    imageProcessId: false,
    imageUrl: false,
    imageSelectIndex: 0,
    session: '',
    generatedLink: '',
    sessionId:false,
    ping: -22,
    frameId: false,
  });
  const location = useLocation()

  console.log({ location })

  useEffect(() => {
    if (form.imageProcessId) {
      replicateCallUpdate(form.imageProcessId, setForm,form.sessionId)
    }
  }, [form.imageProcessId,form.sessionId])


  useEffect(() => {

    if (form.imageUrl) {
      setSteps(2)
    }

  }, [form.imageUrl])

  useEffect(() => {

    if (steps === 1 && form.frameId) {
      ///can now perform api call
      replicateCall(setForm, {
        frameId: form.frameId,//pass the frame id
        prompt: new URLSearchParams(location.search).get('prompt')
      })
    }

  }, [steps])


  // useEffect(() => {
  //   if (!form.session) {
  //     return;
  //   }

  //   const unsubscribe = waitToSetImage(form.session, (data) => {
  //     if (data.available && steps === 0) {
  //       setSteps(1);
  //     }

  //     if (data.donePayment) {
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     }

  //     if (data.viewImage !== form.imageSelectIndex) {
  //       setForm(form => ({
  //         ...form,
  //         imageSelectIndex: data.viewImage,
  //       }));
  //     }

  //     if (data.images && data.images.length && JSON.stringify(form.images) !== JSON.stringify(data.images) &&  data.request ) {

  //       setForm({
  //         ...form,
  //         images: data.images,
  //         frameId: data.request.frameSize,
  //       });
  //       setSteps(2);
  //     }

  //      if (form.images && form.images.length && (!data.images || !data.images.length)) {
  //         ////probably restarted
  //         setSteps(1)
  //         return
  //       }

  //     if (data.ping !== form.ping) {
  //       // setForm(form => ({ ...form, ping: data.ping }));
  //       // setForm((form) => {
  //       //   return {
  //       //     ...form,
  //       //     ping:data.ping,
  //       //   }
  //       // })
  //       // setTimeout(() => {
  //       //   console.log(form.ping, getPing(),data.ping)
  //       // }, 15000)
  //       intervalt = intervalt + 1
  //     }
  //   });

  //   return unsubscribe;
  // }, [form.session, form.images, form.ping, form.imageSelectIndex, form.generatedLink, steps]);

  return (
    <Component>
      {/* {JSON.stringify(form)} */}
      {/* {steps === 0 &&  <StepOne link={form.generatedLink} />} */}
      {/* {steps === 1 && <WaitLoading message="Waiting for image to be made" />} */}
      {steps === 0 && (
        <SelectFrame
          selectedFrame={form.frameId}
          mode={0}
          link={form.generatedLink}
          frameImage={form.imageUrl}
          images={form.images.map((image) => `${image}?beans=${Math.random()}`)}
          imageSelectIndex={form.imageSelectIndex}
          move={(frameId) => {
            console.log('selected', frameId)
            setForm((form) => {
              return {
                ...form,
                frameId
              }
            })
            setSteps(1)
          }}
        />
      )}
      {steps === 1 && <WaitLoading message="Waiting for image to be made" />}

      {steps === 2 && (
        <SelectFrame
          selectedFrame={form.frameId}
          mode={1}
          link={form.generatedLink}
          frameImage={form.imageUrl}
          images={form.images.map((image) => `${image}?beans=${Math.random()}`)}
          imageSelectIndex={form.imageSelectIndex}
          move={(frameId) => {
            setSteps(3)
          }}
        />
      )}

       
      {steps === 3 && <PayApp sessionId={form.sessionId} move={sucess => {
        if (sucess) {
          setForm((form) => {
            return {
              ...form,
              paymentStatus: sucess
            }
          })
        }
        setSteps(4)
      }} images={form.images || []} />}

      {steps === 4 &&

        <ExitComponent goBack={e => {
          setSteps(3)
        }} paymentStatus={form.paymentStatus} />}


    </Component>
  );
}

export default App;

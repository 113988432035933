import React from 'react'
import styled from 'styled-components'
import { getRandomImage } from "config/images";


const Component = styled.div`
  text-align:left;
  font-family: monospace;

  .prompt-creation{
    text-align: center;
    margin:auto;
  }

  .prompt-creation .h1{
    color: white;
    font-size: 20px;
    margin-top: 0;
  }

  .prompt-creation .h6{
    color: white;
    font-size: 18px;
    text-align:left
  }

  .prompt-creation .prompt{
    display:grid;
    grid-template-columns: 1fr 100px;
    justify-content: center;
  }

  input {
    height:45px;
    border:0;
    padding-left:20px;
    font-family: monospace;
  }

  .prompt-creation button {
    margin-left:10px;
    border:0;
    font-size:15px;
    font-family: monospace;
  }

  .prompt{
    border-radius: 8px;
    overflow: hidden;
    background:white;
    border:0
  }

  .prompthome{
    border:1px solid white;
    padding:10px;
    border-radius: 8px;
  }

  .style-choosing{
   }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
      gap: 40px 10px;
    }

    .grid-container > div {
      background-color: rgba(255, 255, 255, 0.8);
      text-align: center;
      width:190px;
      height:190px;
      font-size: 30px;
      position:relative;
      margin: auto;
    } 

    .grid-container  p {
        position: absolute;
        bottom: -47px;
        left: 0px;
        background: white;
        width: 190px;
        font-size: 29px;
     } 

 
     .preview{
      display:grid;
     }

     .preview .image{
      border-radius: 10px;
     }

     .selected p{
      color:white;
      background: purple !important;
     }

      .action{
      position: fixed;
      width: 100vw;
      bottom: 0;
      left: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
     }

      .action button{
      float: left;
      display: block;
      background: #666EE8;
      color: white;
      box-shadow: 0 7px 14px 0 rgba(49, 49, 93, 0.10), 0 3px 6px 0 rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      border: 0;
      margin: 20px 0px;
      font-size: 15px;
      font-weight: 400;
      width: 100%;
      height: 50px;
      line-height: 38px;
      outline: none;
     }

     .main{
      margin-bottom: 111px;
     }
`;


export default function StepStart({ move, setImage, images, canPay }) {
  const [text, setText] = React.useState({})
  const [imgSelected, setImg] = React.useState(0)
  const [selected, setSelected] = React.useState(90)
  const [rtcPeerConnection, setRtcPeerConnection] = React.useState(null);
  const [dataChannel, setDataChannel] = React.useState(null);




  React.useEffect(() => {

  }, []);


  return (
    <Component>
      <div className='main'>

        <div className="prompt-creation">
          <p className="h1">
            {canPay ? "Select an image for the big screen" : "Could you wait a minute,we are making art "}
          </p>


          <div className='style-choosing' >
            {/* <p className="h6" style={{
              textAlign:'center'
            }}>
                Select an image for the big screen
            </p> */}

            <div className="preview">
              <div className="grid-container">

                {
                  images.map((img, i) => {
                    return <div className={i == imgSelected ? 'selected' : ''} style={{
                      background: 'url(' + img + ')',
                      backgroundSize: 'cover',
                      cursor: 'pointer'
                    }} onClick={e => {
                      ///need to send object selected img to the screen
                      setImage(`${i}`)
                      setImg(i)
                    }} key={Math.random()}>
                      <span className="circle">
                        <p className="number">{i + 1}</p>
                      </span>
                    </div>
                  })
                }



              </div>


            </div>


          </div>
        </div>
      </div>
      {canPay && <div className="action">
        <button onClick={e => {
          ///reset stage back to pending
                    
          move('__BACK__')
        }} >Remake art</button>

        <button onClick={e => {
          move(imgSelected)
        }} >Order now</button>
      </div>}
    </Component>

  )
}

import axios from '../config/axios'
import { getAccessToken, setLoginAuth } from './auth'
import { startFireBase } from './firebase'

console.log({ ...axios })
export const makeArt = async (description) => {
	const { data } = await axios.post('/draw', JSON.stringify(description))
	return data
}

export const startProcess = async (form, setForm, setSteps, step) => {

	const url = `${process.env.REACT_APP_API}/draw`
	const options = {
		method: "POST",
		body: JSON.stringify({
			...form
		})
	};
	// setSteps(4)
	fetch(url, options)
		.then((response) => response.json())
		.then(({ status, session }) => {
			///done with process
			if (setSteps) {
				setSteps(step)
			}
			if (!status) {
				return
			}
			setForm({
				...form,
				session
			})
		}).catch(() => {
		});

}


export const replicateService = ( data ) => {

	const url = `${process.env.REACT_APP_API}/replicate`
	const options = {
		method: "POST",
		body: JSON.stringify({
			data
		})
	};
	// setSteps(4)
	const prom =  new Promise((res,rej)=>{

		fetch(url, options)
		.then((response) => response.json())
		.then(({ imageProcessId,imageUrl,sessionId }) => {
			///done with process
			 res({
				imageProcessId,imageUrl,sessionId
			 })
		}).catch(() => {
			rej('')
		});}
	)

	return prom

}

export const replicateCheckService   = ( id,sessionId ) => {

	const url = `${process.env.REACT_APP_API}/replicateupdate?id=${id}&&sessionid=${sessionId}`
	const options = {
		method: "POST",
		body: JSON.stringify({
			prompt
		})
	};
	// setSteps(4)
	const prom =  new Promise((res,rej)=>{

		fetch(url, options)
		.then((response) => response.json())
		.then(({ data,input,completed }) => {
			///done with process
			 res(completed?{
				data,input,
				completed
			 }:{
				completed
			 })
		}).catch(() => {
			rej('')
		});}
	)

	return prom

}



export const pollQueryForQrCode = (qrcode) => {
	const url = `${process.env.REACT_APP_API}/pollqrcode`
	const options = {
		method: "POST",
		body: JSON.stringify({
			qrcode,
			token: getAccessToken()
		})
	};
	// setSteps(4)
	const prom = new Promise((res, rej) => {
		fetch(url, options)
			.then((response) => response.json())
			.then(({ status, activated }) => {

				res(activated)
			}).catch(() => {
				rej(false)
			});

	})

	return prom
}


export const loginUtil = async (loginCode, setSteps) => {

	// setSteps(4)///make it load first 
	const url = `${process.env.REACT_APP_API}/login`
	const options = {
		method: "POST",

		body: JSON.stringify({
			loginCode
		})
	};
	// setSteps(4)
	fetch(url, options)
		.then((response) => response.json())
		.then(({ status, token, refreshToken }) => {

			if (!status) {
				return
			}
			setLoginAuth(token, refreshToken)
		}).catch(() => {

		});

}

export const pollingForForm = async (link, next) => {
	const code = link.replace('//', '').split('/')[2]
	console.log({ link, code })
	const wait = async (delay = 1) => {
		const newPromise = new Promise((res, rej) => {
			setTimeout(() => {
				res({})
			}, delay * 1000);
		})

		await newPromise
	}

	let done = false

	for (let i = 0; i < 40; i++) {
		console.log('polling')
		await wait(2)//wait 2 seconds
		const started = await pollQueryForQrCode(code)
		if (started) {
			done = true
			///process activated so we can stop plling now
			next(2)
			break
		}

	}
	if (!done) {
		console.log('done')
		next(false)
	}
	///i need to creaate a 1 minute wait time to check if the Qr code is scanned, if it doesnt scan in a minute then cancel

}

/**
 * @description load qr code
 */
export const loadQrCodeLink = async (setForm, form, next) => {

	// setSteps(4)///make it load first 
	const url = `${process.env.REACT_APP_API}/qrcode`
	const options = {
		method: "POST",
		body: JSON.stringify({
			token: getAccessToken()
		})
	};
	// setSteps(4)
	fetch(url, options)
		.then((response) => response.json())
		.then(({ status, link }) => {

			if (!status) {
				return
			}

			// setForm({
			// 	...form,
			// 	generatedLink: link
			// })

			next({ link })
			////pass the next function here
			// pollingForForm(link, next)
		}).catch(() => {

		});
}


export const markActivated = async (qrCode, done, fail) => {
	try {
		startFireBase(`${qrCode.split('/')[2]}`)

		///mark the backend too 
		const url = `${process.env.REACT_APP_API}/activatedpollqrcode`

		const options = {
			method: "POST",
			body: JSON.stringify(
				{
					qrcode:`${qrCode.split('/')[2]}`,
					token:getAccessToken()
				}
			)
		};


		const prom = new Promise((res, rej) => {
			fetch(url, options)
				.then((response) => response.json())
				.then(({ status, activated }) => {
					if (!status) {
						fail()
					}else{
						done()
					}

					res(status)
				}).catch(() => {
					alert('fail')
					rej(false)
				});

		})

	

		return prom
	} catch (error) {
		fail()
	}
}


export const setUpChargeId = async (stripe, data, sessionId, setErrorMessage) => {
	const { error, paymentMethod } = await stripe.createPaymentMethod(data);
	console.log({ error, paymentMethod })
	if (!error) {
		console.log({ paymentMethod })
		const url = `${process.env.REACT_APP_API}/processstripecharge`

		const options = {
			method: "POST",
			body: JSON.stringify(
				{
					sessionId,
					chargeId: paymentMethod.id
				}
			)
		};

		console.log({
			options
		})

		const prom = new Promise((res, rej) => {
			fetch(url, options)
				.then((response) => response.json())
				.then(({ status, activated }) => {
					res(status)
				}).catch(() => {
					rej(false)
				});

		})

		return prom
	}
	else {
		setErrorMessage(error.message);
		return false
	}
}




export const updateSession = (session, data) => {
	const url = `${process.env.REACT_APP_API}/updatesession`
	const options = {
		method: "POST",
		body: JSON.stringify(
			{
				sessionId: session,
				data,
				token: getAccessToken()
			}
		)
	};

	const prom = new Promise((res, rej) => {
		fetch(url, options)
			.then((response) => response.json())
			.then(({ status }) => {
				res(status)
			}).catch(() => {
				rej(false)
			});
	})

	return prom
}


import React from 'react'
import styled from 'styled-components'
import { Text, Box } from "@quarkly/widgets";
import { Puff } from 'react-loader-spinner'
import { db, getImageUrl } from 'config/firebase';
import firebase from 'firebase/compat/app';
import {  toast } from 'react-toastify';

// import firebase 

const Component = styled.div`

    .puff-loading{
        display: block;
        text-align: center;
    }

    svg{
        margin: 40px auto;
    }

`

function StepLoading({ message }) {
    
    return (
        <Component>
            <Box
                display="block"
                padding="12px 0"
                text-align="center"
                margin="auto"
                width="fit-content"
                sm-text-align="center"
                justify-content="center"
                align-items="center"
                flex-direction="row"
                md-flex-direction="column"
            >
                <Text
                    color="white"
                    // margin="auto"
                    md-margin="0px 0 20px 0"
                    text-align="left"
                    font="--lead"
                >
                    {message.fail || message}
                </Text>

                {!message.fail && <Puff
                    className='puff-loading'
                    height="150"
                    width="150"
                    radius="9"
                    color="#a100ff"
                    ariaLabel="loading"
                    wrapperStyle
                    wrapperClass
                />}
            </Box>

        </Component>
    )
}

export default StepLoading
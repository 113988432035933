import { getRandomImage } from 'config/images'
import React from 'react'

export default function Images() {
  return (
    <div  className="playkid"   style={{
      position:'absolute',
      transitionDuration: '4s'
    }}>
      <img style={{
        maxHeight:'150px',
        borderRadius:'5px'
      }} src={getRandomImage()} alt="" />
    </div>
  )
}

import { storage } from "config/firebase";
import firebase from 'firebase/compat/app';

let previousData = {};


export const waitForImages = (session, callback, callbackFail) => {
    // Create a storage reference from our storage service
    const unsubscribe = firebase.firestore().collection("generated").doc(session).onSnapshot((doc) => {
        const data = doc.data()
        if (!doc.exists) {
            return
        }

        console.log(data)

        if (!data.status) {
            return
        }

        // Compare the previous data with the new data to determine which fields have changed
        const changedFields = Object.keys(data).filter(key => {
            return JSON.stringify(previousData[key]) !== JSON.stringify(data[key] || 'empty')
        });
        console.log("Changed fields:", changedFields);

        // Update the previous data with the new data
        previousData = data;



        if (data.images && changedFields.includes('imageStage')) {///the process is destroyed
            // unsubscribe()
            callback(data.images.map((image)=>{
                return `${image}?id=${Math.random()}`
            }))
        }
        if (data.status === 'failed') {
            // unsubscribe()
            callbackFail()
        }

        if (data.status==='done'|| data.status==='failed') {
            ///process ended 
            callback({images :data.images.map((image)=>{
                return `${image}?id=${Math.random()}`
            }),
            done:true
            })
            unsubscribe()
        }
    })

    return unsubscribe
}

export const setImage = (session, data) => {
    const docRef = firebase.firestore().collection("generated").doc(session);
    docRef.update({
        ...data
    })
    // .then(() => {
    //     console.log("Document updated successfully!");
    // })
    // .catch((error) => {
    //     console.error("Error updating document: ", error);
    // });
}



export const waitToSetImage = (session, callback) => {
    const unsubscribe = firebase.firestore().collection("generated").doc(session).onSnapshot((doc) => {
        const data = doc.data()

        if (!data) {
            return
        }
        callback(data || 0)

    })

    return unsubscribe
}

export const startFireBase = (session) => {
    firebase.firestore().collection('generated').doc(session).set({
        available: true
    }, { merge: true });

}

export const setToPending  = (session) => {
   return firebase.firestore().collection('generated').doc(session).update({
        status: false,images:[],request:false
    }, { merge: true });
}
import React from 'react';
import Portraits from '../assets/portraitsv2'
import Card from './cards';
import { useHistory } from 'react-router-dom';

function PinterestLayout() {
    const history = useHistory()

    return (
        <div style={styles.pin_container}>


            {Portraits.map(({ image, prompt, size }) => {
                return <Card onClick={e => {
                    history.push(`/app?prompt=${prompt}`)
                }} key={Math.random()} data={{ image, prompt, size }} size={Math.random() > 0.5 ? 'large' : 'medium'} />
            })}


        </div>
    )
}

const styles = {
    pin_container: {
        margin: 0,
        padding: 0,
        width: '100vw',
        position: 'relative',
        left: 0,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 250px)',
        gridAutoRows: '10px',
        justifyContent: 'center',
    }
}

export default PinterestLayout;
import React from 'react'
import styled from 'styled-components'
import { Link } from "@quarkly/widgets";
import { loginUtil } from 'services/api';

const Component = styled.div`

.card{
  max-width: 80%;
  margin: auto;
  width: 400px;
  text-align: center;
  background: white;
  height: 300px;
  padding: 20px;
  border-radius: 10px;
}

.card input{
  width: 80%;
  height: 40px;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid silver;
}

.card button{
  padding:12px 24px 12px 24px;
  color:white;
  background:#a100ff;
  text-decoration-line:initial;
  font:--lead;
  border:0;
  border-radius:3px;
  margin:0px 0px 0px 0px;
  transition:background-color 0.2s ease-in-out 0s;
  hover-background:--color-orange;
  margin:30px 0px 16px 0px;
  sm-text-align:center;
  hover-transition:background-color 0.2s ease-in-out 0s;
}

`
export default function Login() {
  const [code,setCode] = React.useState('')
  return (
    <Component>
      <div className="card">
        <h2>
          Login
        </h2>

        <input onChange={e=>{
          setCode(e.target.value)
        }} type="text" placeholder='place in login code' />

        <button
                onClick={e=>{
                    loginUtil(code)
                }}
            >
               Login
            </button>
      </div>
    </Component>
  )
}

import Cookies from 'js-cookie'

export const setLoginAuth = (token,refreshToken) =>{
    Cookies.set('access_token', token)
    Cookies.set('refresh_token', refreshToken)
    window.location.reload()
}

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()
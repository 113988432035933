import React from 'react';

function Card({data,...props}) {
    return (
        <div  onClick={props.onClick}  style={{
            ...styles.card,
            ...styles[props.size],
            background:`url(${data.image})`,
            backgroundSize:'cover'
            
        }}>
        </div>
    )
}

const styles = {
    card: {
        margin: '15px 10px',
        padding: 0,
        borderRadius: '16px',
        backgroundColor: 'black'
    },
    small: {
        gridRowEnd: 'span 26'
    },
    medium: {
        gridRowEnd: 'span 33'
    },
    large: {
        gridRowEnd: 'span 45'
    }
}

export default Card;